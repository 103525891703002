export default httpClient => ({
  totalVendaGrafico: async ( data ) => {

    const response = await httpClient.post('/api/dashboard/vendas/totalNoPeriodo/grafico', {
      periodoEmDias: data.periodoEmDias
    })

    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
        payload: response.request.response
      }
    }

    return {
      data: response.data,
      errors
    }
  },
  totalVendaTabela: async ( data ) => {

    const response = await httpClient.post('/api/dashboard/vendas/totalNoPeriodo/tabela', {
      periodoEmDias: data.periodoEmDias
    })

    let errors = TratarRetorno(response)

    return {
      data: response.data,
      errors
    }
  },
  provisaoPagamentosReceber: async () => {
    const response = await httpClient.get('/api/dashboard/pagamentos/provisoes/receber')

    let errors = TratarRetorno(response)

    return {
      data: response.data,
      errors
    }
  }
})

function TratarRetorno(response) {
  let errors = null
  if (!response.data) {
    errors = {
      status: response.request.status,
      statusText: response.request.statusText,
      payload: response.request.response
    }
  }
  return errors
}
