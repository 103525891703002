<template>
  <nav class="navbar navbar-expand navbar-dark subheader">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'subheader'
}
</script>
