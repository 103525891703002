import { createStore } from 'vuex'

import auth from './modules/auth.js'
import clientes from './modules/clientes.js'
import enderecos from './modules/enderecos.js'
import formasPagamento from './modules/formasPagamento.js'
import pagamentos from './modules/Pagamentos.js'
import vendas from './modules/vendas.js'
import vendedores from './modules/vendedores.js'
import { useWindowSize } from '@vueuse/core'

const { width, height } = useWindowSize()

export default createStore({
  modules: {
    auth,
    clientes,
    enderecos,
    formasPagamento,
    pagamentos,
    vendas,
    vendedores
  },
  state: {
    exibirHeader: true,
    ocupado: false,
    carregando: false,
    info: {},
    fingerprint: null,
    online: false,
    screen: { width, height },
    default: null,
    parametros: {
      pedido: {
        cfop: 5102,
        precisaoQtd: 4
      }
    }
  },
  mutations: {
    Ocupado (state, ocupado) {
      state.ocupado = ocupado
    },
    Carregando (state, carregando) {
      state.carregando = carregando
    },
    ExibirHeader (state, status) {
      state.exibirHeader = status
    },
    DefinirSistema(state, sistema) {
      state.info.sistema = sistema
    },
    DefinirEmpresa(state, empresa) {
      state.info.empresa = empresa
    },
    DefinirFingerprint(state, fingerprint) {
      state.fingerprint = fingerprint
    },
    ONLINE(state, online) {
      state.online = online
    },
    DefaultValues(state, defaultValues) {
      state.default = defaultValues
    }
  },
  actions: {
  },
  getters: {
    exibirHeader: state => state.exibirHeader,
    ocupado: state => state.ocupado,
    carregando: state => state.carregando,
    info: state => state.info.sistema,
    fingerprint: state => state.fingerprint,
    online: state => state.online,
    screen: state => state.screen,
    defaultValues: state => state.default,
    parametros: state => state.parametros,
    empresa: state => state.info.empresa
  }
})
