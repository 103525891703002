import Store from '@/store';

export default httpClient => ({
  login: async ({ email, password, cnpj, fingerprint }) => {
    localStorage.setItem('tenantid', cnpj)
    const response = await httpClient.post('/api/auth/login', {
      email,
      password,
      cnpj: localStorage.getItem('tenantid'),
      fingerprint
    })

    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
        payload: response.request.response
      }
    } else {
      const payload = {
        name: response.data.data.name,
        token: response.data.data.token,
        cnpj: cnpj
      }
      Store.commit('AUTH_SUCCESS', payload)
      Store.commit('ExibirHeader', true)
    }

    return {
      data: response.data,
      errors
    }
  },
  forgotPassword: async({ email, urlCallback, cnpj }) => {
    const response = await httpClient.post('/api/auth/forgotPassword', {
      email,
      urlCallback,
      cnpj
    })

    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
        payload: response.request.response
      }
    }

    return {
      data: response.data,
      errors
    }
  },
  resetPassword: async({ email, password, confirmPassword, cnpj, token }) => {
    const response = await httpClient.post('/api/auth/resetPassword', {
      email,
      password,
      confirmPassword,
      token,
      cnpj
    })

    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
        payload: response.request.response
      }
    }

    return {
      data: response.data,
      errors
    }
  },
  confirmEmail: async({ email, cnpj, token}) => {
    const response = await httpClient.post('/api/auth/user/confirmEmail', {
      email, cnpj, token
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
        payload: response.request.response
      }
    }

    return {
      data: response.data,
      errors
    }
  }
})
