
export default {
  state: {
    formasPagamento: [],
    prazosPagamento: []
  },
  mutations: {
    DefinirFormasPagamento(state, formas) {
      if (!formas) {
        state.formasPagamento = []
        return
      }
      state.formasPagamento = formas
    },
    AddOrUpdateFormaPagamento (state, formaPagamento) {
      let i = state.formasPagamento.map(x => x.idFormaPagamento).indexOf(formaPagamento.idFormaPagamento)
      if (i >= 0) {
        state.formasPagamento[i] = formaPagamento
      } else {
        state.formasPagamento.push(formaPagamento)
      }
    },
    ExcluirFormaPagamento (state, idFormaPagamento) {
      let i = state.formasPagamento.map(x => x.idFormaPagamento).indexOf(idFormaPagamento)
      if (i >= 0) { state.formasPagamento.splice(i, 1) }
    },
    DefinirPrazosPagamento (state, prazos) {
      if (!prazos) {
        state.prazosPagamento = []
        return
      }
      state.prazosPagamento = prazos
    },
    AddOrUpdatePrazoPagamento (state, prazo) {
      let i = state.prazosPagamento.map(x => x.idPrazoPagamento).indexOf(prazo.idPrazoPagamento)
      if (i >= 0) {
        state.prazosPagamento[i] = prazo
      } else {
        state.prazosPagamento.push(prazo)
      }
    },
    ExcluirPrazoPagamento (state, idPrazoPagamento) {
      let i = state.prazosPagamento.map(x => x.idPrazoPagamento).indexOf(idPrazoPagamento)
      if (i >= 0) { state.prazosPagamento.splice(i, 1) }
    }
  },
  actions: {
  },
  getters: {
    formasPagamento: (state) => state.formasPagamento,
    prazosPagamento: (state) => state.prazosPagamento,
    prazosPagamentoPorFormaPagamento: (state) => (formas) => {
      try {
        let filtrar = (idFormaPagamento) => {
          var idx = formas.findIndex(x => x.idFormaPagamento === idFormaPagamento.toString())
          return idx >= 0
        }
        var t = state.formasPagamento.filter(x => filtrar(x.idFormaPagamento))
        return t
      } catch (e) {
        console.error(e)
        return []
      }
    }
  }
}
