<template>
  <div class="card" style="max-width: 500px;">
    <div class="card-body">
      <h5 class="card-subtitle mb-2 text-muted">Consultar Situação da NFe</h5>
      <div class="row mt-4">
        <div class="col">
          <div class="input-group mb-3">
            <h6 class="mb-2">Consultar Documentos Destinados na Sefaz por NSU</h6>
            <input type="text" v-model="nsu" class="form-control mb-2" placeholder="NSU" maxlength="30">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  console.log('=========== ALGOPLUS HOME ===========')
  console.log('==== http://algoplus.com.br')
  console.log('==== CARLOS ALEXANDRE LARANJEIRAS')
  console.log('==== Whats: (21) 997706037')
  console.log('=====================================')
})
</script>
