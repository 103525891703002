import http from '@/http.js'
import { parseJwt } from '@/tools/Jwt.js'

const state = () => ({
  user: JSON.parse(localStorage.getItem('user')),
  token: localStorage.getItem('token'),
  tenantid: localStorage.getItem('tenantid')
})

// mutations
const mutations = {
  AUTH_SUCCESS: (state, payload) => {
    state.token = payload.token
    localStorage.setItem('token', payload.token)
    const jwt = parseJwt(payload.token)
    const user = {
      name: payload.name,
      email: jwt.Email,
      cnpj: payload.cnpj,
      roles: jwt.role,
      isAdmin: jwt.role.includes('ADMIN')
    }
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('tenantid', payload.cnpj)
  },
  SET_TENANTID: (state, tenantid) => {
    localStorage.setItem('tenantid', tenantid)
  },
  AUTH_ERROR: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  },
  AUTH_LOGOUT () {
    console.log('LOGOUT')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }
}

// actions
const actions = {

  AUTH_LOGOUT ({commit}) {
    return new Promise((resolve) => {
      commit('AUTH_LOGOUT')
      commit('ExibirHeader', false)
      delete http.defaults.headers.common['Authorization']
      resolve()
    })
  }
}

// getters
const getters = {
  authenticated: state => {
    try {
      let auth = !!state.token
      return auth
    } catch (error) {
      console.error(error)
      return false
    }
  },
  token: state => {
    try {
      return state.token
    } catch {
      return null
    }
  },
  user: (state) => {
    try {
      return state.user
    }
    catch (e) {
      console.error(e)
      return null
    }
  },
  isAdmin: state => state.user?.isAdmin ?? false,
  tenantid: state => state.tenantid,
  jwtInfo: state => parseJwt(state.token)
}

export default {
  state,
  getters,
  actions,
  mutations
}
