
const state = () => ({
  clientes: []
})

// mutations
const mutations = {
  DefinirClientes (state, clientes) {
    state.clientes = clientes
  },
  AtualizarCliente (state, cliente) {
    let i = state.clientes.map(x => x.idPedido).indexOf(cliente.idPedido)
    if (i >= 0) {
      state.clientes[i] = cliente
    } else {
      state.clientes.push(cliente)
    }
  }
}

// actions
const actions = {
}

// getters
const getters = {
  clientes: state => state.clientes
}

export default {
  state,
  getters,
  actions,
  mutations
}