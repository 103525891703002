
const state = () => ({
  vendedores: []
})

// mutations
const mutations = {
  AtualizarVendedores (state, vendedores) {
    state.vendedores = vendedores
  }
}

// actions
const actions = {
}

// getters
const getters = {
  vendedores: state => state.vendedores
}

export default {
  state,
  getters,
  actions,
  mutations
}